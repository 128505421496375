import React from 'react'
import {useContext} from 'react';
import {translate} from './translate.js'
import './footer.scss'
import {returnImgPath, arr_menu, open_menu} from './functions'
import {setLanguage} from './translate'
import {ThemeContext} from './App'
function Footer()
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
	/*
function open(item) 
{
//window.location.hash='#'+item
  if (typeof item.link!=='undefined') window.open(item.link);
  else if (typeof document.getElementById(item.a)==='undefined' || document.getElementById(item.a)===null) window.location.href='/#'+item.a
  else
   {
window.history.replaceState(null, null, document.location.pathname+'#'+item.a);
window.scrollTo({top: document.getElementById(item.a).offsetTop-80,behavior: "smooth"})
   }
}*/

const {theme, setTheme, lang, setLang} = useContext(ThemeContext);
const switchTheme=()=>
{
  if (theme==='black_theme') setTheme('white_theme')
  if (theme==='white_theme') setTheme('black_theme')
}
const switchLang=()=>
{
let targetLang;
  if (lang==='ru') targetLang='en'
  if (lang==='en') targetLang='ru'
setLanguage(targetLang)
setLang(targetLang)
}
return(
<div className="footer" id="footer">
	<div className="footer_column_1">
<img alt="Protone" src={returnImgPath(theme,"logo.svg")} className="footer_logo" />
		<div className="footer_menu">
<div className="footer_menu_left">{arr_menu.map((it,i)=>(i<3)?<div className="footer_menu_item" onClick={()=>open_menu(it)} key={'footer_menu_'+i} id={'footer_menu_'+it.a} data-key={it.a}>{translate(it.name)}</div>:'')}</div>
<div className="footer_menu_right">{arr_menu.map((it,i)=>(i>2)?<div className="footer_menu_item" onClick={()=>open_menu(it)} key={'footer_menu_'+i} id={'footer_menu_'+it.a} data-key={it.a}>{translate(it.name)}</div>:'')}</div>
		</div>
<div className="footer_vert_hr_1"></div>
	</div>
	<div className="footer_column_2">
<div className="footer_social">
<a href="https://t.me/protone" target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"soc_tel.svg")} className="footer_soc_img" alt="" /></a>
<a href="https://www.tiktok.com/@protone.app" target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"soc_tik.svg")} className="footer_soc_img" alt="" /></a>
<a href="https://www.vk.com/protone_app" target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"soc_vk.svg")} className="footer_soc_img" alt="" /></a>
</div>
<div className="footer_copy">©2025 - Protone  |   All right reserved</div>
<a href="https://protone.app/eula_protone.pdf" className="footer_copy_link" rel="noreferrer" target="_blank">{translate('Политика конфиденциальности')}</a>
	</div>
	<div className="footer_column_3">
<div className="footer_language_mobile">
	<div className="footer_language_mobile_wrapper">
		<div>{translate('Тема')}</div>
		<img className="img_theme" src={returnImgPath(theme,"theme.svg")} alt=""  onClick={switchTheme} />
	</div>
	<div className="footer_language_mobile_wrapper">
		<div>{translate('Язык')}</div>
			<div className="img_lang_wrapper" onClick={switchLang}>
<img className="img_lang" src={(lang==='ru')?'/i/lang/en.png':'/i/lang/ru.png'} alt=""  />
<div>{(lang==='ru')?'en':'ru'}</div>	
			</div>
	</div>
</div>
<div className="footer_vert_hr_2"></div>
		<div className="footer_desktop">
<div className="footer_title">{translate('Protone — твой проводник  в мир яркой городской  жизни')}</div>
<img  src={returnImgPath(theme,"qr.png")} alt="" className="footer_qr" />
<div className="footer_text">{translate('Загрузи приложение и присоединяйся к самому активному сообществу.')}</div>
		</div>
		<div className="footer_mobile">
<div className="footer_title">{translate('Protone - твой проводник  в мир яркой городской  жизни')}</div>
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"button_apple.svg")} alt="" className="footer_app_apple" /></a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"button_google.svg")} alt="" className="footer_app_google" /></a>
<div className="footer_text">{translate('Загрузи приложение и присоединяйся к самому активному сообществу.')}</div>
<div className="footer_mobile_hr"></div>
		</div>
	</div>

</div>
)
}


export default Footer;