//import type {LngLat, RouteFeature} from '@yandex/ymaps3-types';
 //import {getPointStr, fetchRoute, lineStyle, InfoMessage} from './yandex_common.ts';
import './map.scss';
import React, {useState, useEffect, useRef, useCallback, useMemo, memo} from "react";
//import { useHistory } from 'react-router-dom';
import * as ReactDOM from 'react-dom';
//import {returnPointbyPointType} from "./api";
import {isMobile} from './functions.js'
import {encodeGeoHash} from "./geohash";
const ymaps3=window.ymaps3
const [ymaps3React] = await Promise.all([ymaps3.import('@yandex/ymaps3-reactify'), ymaps3.ready]);
const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);
const {YMap, YMapDefaultSchemeLayer, YMapControls, YMapDefaultFeaturesLayer, YMapMarker, YMapFeatureDataSource, YMapLayer/*, BEHAVIOR, EventListener*/, YMapListener} =
reactify.module(ymaps3);
const {YMapZoomControl, YMapGeolocationControl} = reactify.module(await ymaps3.import('@yandex/ymaps3-controls@0.0.1'));
const {YMapClusterer, clusterByGrid} = reactify.module(await ymaps3.import('@yandex/ymaps3-clusterer@0.0.1'));
//const {BehaviorMapEventHandler} = reactify.module(await ymaps3.import('@yandex/ymaps3-controls-extra'));
let timerUpdateCoord;
let timerUpdatePoints;
/*const INITIAL_ROUTE_POINTS=
[
  [37.620028, 55.741556],
  [38.130492, 56.31112]
];*/


function getBounds(coordinates)
{
let minLat=Infinity, minLng=Infinity
let maxLat=-Infinity, maxLng=-Infinity

  for (const coords of coordinates) 
   {
const lat=coords[1], lng=coords[0]
    if (lat<minLat) minLat=lat
    if (lat>maxLat) maxLat=lat
    if (lng<minLng) minLng=lng
    if (lng>maxLng) maxLng=lng
   }
minLng=minLng-0.002
minLat=minLat-0.002;
maxLng=maxLng+0.002;
maxLat=maxLat+0.002
return [
    [minLng, minLat],
    [maxLng, maxLat]
];
}
const setActivePoint=(e,id)=> //remove selected points classes and add class to current
{
const elems=document.getElementsByClassName('map_selected')
  if (elems.length>0)  Array.from(elems).forEach((item)=>item.classList.remove('map_selected'))
e.target.parentNode.classList.add('map_selected')
window.history.replaceState( {},'','/point/'+id)
}

function Cluster(props)
{
	

const [current,setCurrent]=useState(0)

const gridSizedMethod = useMemo(() => clusterByGrid({gridSize: 64}), []);
const refCurrent=useRef(null)
refCurrent.current=current
useEffect(() => {
setCurrent(0) //remove active point when init
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.arr.length]);
const open=useCallback((e,click,id,mergedIds)=>
{ 
const supportTouch=(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
const mobile=(/*window.innerWidth<=768 || */isMobile)?true:false;
  if ((mobile || supportTouch) && click===true) return;
setCurrent(id);
props.popup(e,id,mergedIds)
},[props])

const marker = useCallback(
(feature) => {
let startTime=0
return (
    <YMapMarker coordinates={feature.geometry.coordinates} source={'my-source'}>
    <section className="map_section" onTouchCancel={(e)=>{e.preventDefault();}} onTouchEnd={(e)=>{ if( (Date.now()-startTime)<500 ) {window.setTimeout((e)=>{open(e,false,feature.properties.id)},50,e);startTime=0}}}onTouchStart={(e)=>{startTime=Date.now();}}  onClick={(e)=>{open(e,true,feature.properties.id,feature.properties.mergedIds)}}>
    <div  style={{width:33,height:'auto'}} id={"point_"+feature.properties.id} onClick={(e)=>setActivePoint(e,feature.properties.id)} className={(refCurrent.current===feature.properties.id)?'map_selected':'map_div'}>
<img className="map_icon" src={feature.properties.icon}  alt=""/>
	</div>
    </section>

    </YMapMarker>
  )},
  [open]
);
const cluster = useCallback(
  (coordinates, features) => 
{
  const clickMe=async ()=>
  {
const curZoom=await props.getcoord()
const bounds = getBounds(features.map((feature: Feature) => feature.geometry.coordinates));
//<18
  if (curZoom<17) props.setLoca(({...props.loca, bounds, easing: 'ease-in-out', duration: 600}));
  else props.setLoca({center: [coordinates[0], coordinates[1]], zoom:curZoom+2, easing: 'ease-in-out', duration: 600})
  //props.setCoord({x:coordinates[0],y:coordinates[1],zoom:curZoom+2})
//let curZoom=await props.getcoord()
//  if (isMobile) props.setCoord({x:coordinates[0],y:coordinates[1],zoom:(curZoom<10)?10:curZoom+1})
 // else props.setCoord({x:coordinates[0],y:coordinates[1],zoom:(curZoom<16)?16:curZoom+1})

 // 	  console.log(window.ymaps3)
//const map=window.ymaps3.YMap;

	  //console.log(props.map)
  	  	  /*
window.ymaps3.geocode('������, ����� ���� ��������, 16').then(function (res) {
    var coords = res.geoObjects.get(0).geometry.getCoordinates();
    map.zoomRange.get(coords).then(function (range) {
        map.setCenter(coords, range[1]);
    });
});	  */
  }
   return (
    <YMapMarker coordinates={coordinates} source={'my-source'}>
<div className="circle" onClick={clickMe}>
        <div className="circle-content">
          <span className="circle-text">{features.length}</span>
        </div>
      </div>
    </YMapMarker>
  )}, // eslint-disable-next-line react-hooks/exhaustive-deps
  []
);
const coordinates = props.arr.map((item)=>{return [item.longitude,item.latitude]})
	
const points = coordinates.map((lnglat, i) => 
({
  type: 'Feature',
  id: i,
  geometry: {coordinates: lnglat},
properties: {id:props.arr[i].id,icon:props.arr[i].icon,mergedIds:props.arr[i].mergedIds}
})
);
return(
        <YMapClusterer
            method={gridSizedMethod}
            features={points}
            marker={marker}
             cluster={cluster}
        />
)
}



const Map=memo((props)=>
{
            // Status of behavior events
           /*  const [behaviorEvents, setBehaviorEvents] = useState({
                  scrollZoom: false,
                  drag: false,
                  mouseRotate: false,
                  mouseTilt: false
              });*/
const refMap = React.useRef(null);
//const [zoom, setZoom] = useState(13);
const [coord,setCoord]=useState({x:props.x,y:props.y,zoom:props.zoom})
const [loca,setLoca]=useState(false)
//const [route, setRoute] = useState (null);
const setMapRef = useCallback((e) => {
  refMap.current = e;
}, []);
useEffect(() => {
setCoord({...coord,x:props.x,y:props.y,zoom:(typeof props.zoom!=='undefined')?props.zoom:13})
	//buildDrivingRoute()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.x,props.y]);

useEffect(() => {
if (typeof coord.x!=='undefined')
{
setLoca({center: [coord.x, coord.y], zoom:coord.zoom})
}
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [coord]);

useEffect(() => {
  if (refMap.current!==null) updatePoints()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.updateNow]);

useEffect(() => {
  if (refMap.current!==null) updatePoints()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.arr.length]);


       /*   const buildDrivingRoute = useCallback(
            () => fetchRoute(INITIAL_ROUTE_POINTS[0], INITIAL_ROUTE_POINTS[1], 'driving').then(routeHandler),
            []
          );
          const buildTruckRoute = useCallback(
            () => fetchRoute(INITIAL_ROUTE_POINTS[0], INITIAL_ROUTE_POINTS[1], 'truck').then(routeHandler),
            []
          );
          const buildWalkingRoute = useCallback(
            () => fetchRoute(INITIAL_ROUTE_POINTS[0], INITIAL_ROUTE_POINTS[1], 'walking').then(routeHandler),
            []
          );
          const buildTransitRoute = useCallback(
            () => fetchRoute(INITIAL_ROUTE_POINTS[0], INITIAL_ROUTE_POINTS[1], 'transit').then(routeHandler),
            []
          );
          //A handler function that updates the route line and shifts the map to the new route boundaries, if they are available. 
          const routeHandler = useCallback((newRoute: RouteFeature) => {
            // If the route is not found, then we alert a message and clear the route line
            if (!newRoute) {
              alert('Route not found');
              setRoute(null);
              return;
            }

            setRoute(newRoute);
           //if (newRoute.properties.bounds) {
            // setLocation({bounds: newRoute.properties.bounds, duration: 300});
           // }
          }, []);*/

const getCamera = () => {
    return new Promise((resolve, reject) => {
      if (refMap.current) {
resolve(refMap.current.zoom)
         /*   refMap.current.getCameraPosition((position) => {
          resolve(position);
        });*/
      } else {
        reject('ERROR');
      }
    })

}


  // Function that creates a handler function to change the status of behavior event
              const createBehaviorEventHandler = useCallback((isStart: boolean): BehaviorMapEventHandler => {
                  return function (object) {
                      if (object.type === 'dblClick') return;

                      if (isStart) {
                      if (object.type === 'scrollZoom')  {
                      	 // if (typeof props.closePoint!=='undefined')  props.closePoint.setShowPointInfo(false)
/*const el=document.getElementsByClassName('map_selected')
  if (el.length>=1) 
   {
el[0].classList.add('map_div')
el[0].classList.remove('map_selected')
   }*/
                      	 //  console.log('actionStart object: ', object,props.closePoint);
                      return}
                       
                      
                      } 
                      /*else {
                          //console.log('actionEnd object: ', object);
                      }*/

                   //   behaviorEvents[object.type] = isStart;
                  //    setBehaviorEvents({...behaviorEvents});
                  };// eslint-disable-next-line react-hooks/exhaustive-deps
              },[]);

function updatePoints()
{

const geohash=encodeGeoHash(refMap.current.center[1],refMap.current.center[0],4)/*9*/
function returnArrFiltered(points,geohash,length)
{
let rez=[];
//geohash=geohash.substr(0,4)
   for (let i=0 ; i<points.length; i++)
   { 
       if (points[i].geohash2.substr(0,length)===geohash) 
        {
const item=points[i]
item.distance=Math.acos(Math.sin(refMap.current.center[1]*3.14159/180)*Math.sin(item.latitude*3.14159/180)+Math.cos(refMap.current.center[1]*3.14159/180)*Math.cos(item.latitude*3.14159/180)*Math.cos((refMap.current.center[0]-item.longitude)*3.14159/180))*6371.21
//item.distance=Math.acos(Math.sin(refMap.current.center[0]*3.14159/180)*Math.sin(item.latitude*3.14159/180)+Math.cos(refMap.current.center[0]*3.14159/180)*Math.cos(item.latitude*3.14159/180)*Math.cos((refMap.current.center[1]-item.longitude)*3.14159/180))*6371.21
rez.push(item)
        }
    }
  if (rez.length===0 && length>2) return returnArrFiltered(points,geohash.substr(0,length-1),length-1)
//console.log(geohash,props.arr[0].geohash,encodeGeoHash(refMap.current.center[0],refMap.current.center[1],4))
return rez
//  if (rez.length===0) rez=null
//console.log(rez)
}
  if (props.arr.length>0 && refMap.current!==null) 
   {
//���������� ������ ����� ��� 3 �������� �������
let points=[]
const temp_geohash=geohash.substr(0,2)
  for (let g=0 ; g<props.arr.length; g++)
   {
if (props.arr[g].geohash2.substr(0,2)===temp_geohash) points.push(props.arr[g])
   }
let rez=returnArrFiltered(points,geohash,4)
rez.sort((a, b) => a.distance - b.distance)
props.setCurrentPoints(rez)
   }

//                  console.log('Update object: ', object);
//ymaps3.geoQuery(ymaps3.geocode('�����-���������'));
//  	      var bounds = refMap.current.getBounds();
  //	      console.log(bounds)
   /* var contains = ymaps.util.bounds.containsPoint(bounds, item.center);
    if (contains) {
    	
    }*/
               //   object.mapInAction ? (mapEvents.update = true) : (mapEvents.update = false);
                //  setMapEvents({...mapEvents});
                // eslint-disable-next-line react-hooks/exhaustive-deps
}
              // Handler function for changing the status of the onUpdate event
const updateHandler: MapEventUpdateHandler = useCallback((object) =>
{
  if (typeof refMap.current!=='undefined' && refMap.current.center[1]!==props.y && refMap.current.center[0]!==props.x) 
   {
window.clearTimeout(timerUpdateCoord)
timerUpdateCoord=window.setTimeout(function(){setCoord({x:refMap.current.center[0],y:refMap.current.center[1],zoom:object.location.zoom})},100)
 }
window.clearTimeout(timerUpdatePoints)
timerUpdatePoints=window.setTimeout(()=>updatePoints(),200) /*300 1000*/
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [refMap.current,props.arr.length,props.updateNow]);

	if (typeof props.x==='undefined' || typeof props.arr==='undefined' || props.arr===false) return ''
//console.log(returnPointbyPointType(props.icons,props.arr[0].pointOfInterestTypeId))
  return ( 
  	  <div className="map">
	<YMap location={(loca!==false)?loca:{center: [55.755819, 37.617644], zoom: 13}} mode="vector" ref={setMapRef} zoomRange={{min:5,max:23}}    >
  <YMapControls position="right">
                <YMapZoomControl    />
<YMapGeolocationControl position="right" />
              </YMapControls>
<YMapListener
			onActionStart={createBehaviorEventHandler(true)}
                /*    onActionEnd={createBehaviorEventHandler(false)}*/
                	onUpdate={updateHandler}
                      />
  <YMapDefaultSchemeLayer />
  <YMapDefaultFeaturesLayer />
        <YMapFeatureDataSource id="my-source"/>
        <YMapLayer source="my-source" type="markers" zIndex={1800}  />
        	  	  		<Cluster popup={props.popup} arr={props.arr}  icons={props.icons} map={refMap.current} getcoord={getCamera} /*setZoom={setZoom}*/ setCoord={setCoord} loca={loca} setLoca={setLoca} />

</YMap>
	  </div>
  );
},(prevProps, nextProps) => (prevProps.arr.length === nextProps.arr.length && prevProps.x===nextProps.x && prevProps.updateNow===nextProps.updateNow))
export default Map;